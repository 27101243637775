
  .Auth-form-container {
    display: grid;
    grid-template-columns: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 55vh;
  }

  .Auth-form {
    width: 400px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    background-color: whitesmoke;
    color: black;
    margin-left: 5%;
    margin-right: 5%;
  }


  .Auth-form-container input{
    width:fit-content;
    border-radius: 10px;
    font-weight: 700;
    
  }

  .pwd {
    display: flex;    
    margin-top:20px;
    margin-bottom:20px;
    position: relative;
  }

  .show {
    display: flex;
    position: absolute;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 10px;
    top: 0;
    border-radius: 5px;
    right: -0px;
    z-index: 2;
    border: none;
    top: 12px;
    height: 20px;
    cursor: pointer;
    color: red;
    background-color: white;
    transform: translateX(2px);
    text-align: center;
    font-size: small;
    align-items: center;
    text-align: start;
    width: fit-content;
  }

  .Auth-form-content {
    padding-left: 10%;
    padding-right: 10%;
  }

  .Auth-form-content input {
    padding: 4%;
    font-size: 10px;
    width: 200px;
  }

  .Auth-form-title {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    font-size: 18px;
    color: rgb(34, 34, 34);
    font-weight: 800;
  }

  .login {
    display: grid;
    margin-top: 50px;
    justify-content: center;
  }

  .Auth-form-content button {
    width: 100px;
    margin-bottom: 1em;
    justify-content: left;
  }

  #help{
    font-size:12px;
    margin-bottom:10px;
  }

