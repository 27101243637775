.App {
  background-color: white;
}


label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}


.error {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  margin-top: 100px;
}

.home {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  margin-top: 100px;
}

.home--text {
  margin-top: 20px;
  margin-left: 20%;
  margin-right: 20%;
  color: red;
  flex: wrap;
}

label {
  padding: 0.5em 0.5em 0.5em 0.5em;
}


input:focus {
  outline: 3px solid gold;
}

select {
  height: 100%;
}

.navi-header{
  margin:auto;
}