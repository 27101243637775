.error{
    margin-top:10%;
    display:grid;
    grid-template-columns: auto;

    justify-content: center;
}

.error-text{
    margin-left:30px;
}

.error--button{
    justify-content: center;
    margin-top:30%;
    border-radius:20px;
    padding: 20px;
  }